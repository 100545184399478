<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <b-alert
                :show="dismissCountDownSuccess"
                dismissible
                fade
                variant="success"
                @dismiss-count-down="countDownChangedSuccess"
                >
                {{ messageSuccess }}
            </b-alert>
            <b-alert
                :show="dismissCountDownFailuer"
                dismissible
                fade
                variant="danger"
                @dismiss-count-down="countDownChangedFailuer"
                >
                {{ messageFailuer }}
            </b-alert>
            <div class="card-body">
                <h4 class="header-title m-t-0">Dropzone File Upload</h4>
                <!-- file upload -->
                <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" :duplicateCheck="true" @vdropzone-error="handleErrors" @vdropzone-success="handleSuccess" @vdropzone-total-upload-progress="handleProgress">
                    <div class="dz-message needsclick">
                        <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                        <h3>Drop files here or click to upload.</h3>
                    </div>
                </vue-dropzone>
                <b-progress :value="progress" show-progress variant="success" striped :animated="true" class="mt-3" v-if="isProgressing"></b-progress>
                <!-- <b-button class="mt-3" variant="outline-primary" @click="$router.go(-1)"><i class="fas fa-arrow-left mr-1"></i> Go back</b-button> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import userService from '@/helpers/userService'
import errorHandler from '../../helpers/errorHandler'

export default {
    layout:'ticket',
    name: 'upload',
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                url: `${process.env.VUE_APP_BASE_URL}material/add_material?chapter_id=${this.$route.params.chapter_id}`,
                thumbnailWidth: 150,
                maxFilesize: 100,
                headers: {
                    'My-Awesome-Header': 'header value',
                    'Authorization': `Bearer ${userService.methods.userToken()}`
                },
                previewTemplate: this.template(),
                paramName: 'file',
                uploadMultiple: false
            },
            dismissSecs: 5,
            dismissCountDownSuccess: 0,
            dismissCountDownFailuer: 0,
            messageSuccess:'',
            messageFailuer:'',
            progress: 0,
            isProgressing: false
        }
    },
    methods: {
        template: function () {
            return ` <div class="dropzone-previews mt-3">
            <div class="card mt-1 mb-0 shadow-none border">
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col">
                            <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                            <p class="mb-0" data-dz-size></p>
                        </div>
                        <div class="dz-preview dz-file-preview col-auto">
                            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        },
        handleErrors(file, message, xhr){
            this.$refs.myVueDropzone.removeFile(file);
            this.messageFailuer = message.detail['error message'];
            this.showAlertFailuer();
            this.isProgressing = false;
            this.progress = 0;
            errorHandler.methods.errorUpload(message)
        },
        handleSuccess(file, response){
            this.messageSuccess = file.name + ' is uploaded successfully!'
            this.showAlertSuccess();
            this.isProgressing = false;
            this.progress = 0;
            errorHandler.methods.successful('File is uploaded successfully!')
        },
        handleProgress(totaluploadprogress, totalBytes, totalBytesSent){
            this.progress = totaluploadprogress
            if(totaluploadprogress === 0){
                this.isProgressing = false;
            }else{
                this.isProgressing = true;
            }
        },
        countDownChangedSuccess(dismissCountDownSuccess) {
            this.dismissCountDownSuccess = dismissCountDownSuccess
        },
        countDownChangedFailuer(dismissCountDownFailuer) {
            this.dismissCountDownFailuer = dismissCountDownFailuer
        },
        showAlertSuccess() {
            this.dismissCountDownSuccess = this.dismissSecs
        },
        showAlertFailuer() {
            this.dismissCountDownFailuer = this.dismissSecs
        }
    },

}
</script>

<style>

</style>